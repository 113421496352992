import {
  SendCodeValidationOrigin,
  SendCodeValidationStorage,
} from '@/data/use-cases/register-maintenance-security-validation/remote-send-code-validation'
import { CHANNEL_STORAGE_NAME, SEND_CODE_VALIDATION_ORIGIN } from '@/domain/models/address'
import {
  MaintenanceSecurityValidationMessages,
  MaintenanceSecurityValidationOptions,
} from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { ControlledFVRadioButton } from '@/main/components/radio-button'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { getObjectInStorage, setObjectInStorage } from '@/main/hooks/useStorage'
import { createUrlAddressMaintenance } from '@/utils/create-url-address-maintenance'
import { Typography } from '@naturacosmeticos/natds-web'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Container } from '../register-maintenance-address/components/pages-structures'
import { DialogWithLoader } from '../register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'
import { SecurityValidationPageApi } from './api/make-maintenance-security-validation-page-api'
import { MaintenanceSecurityValidationContactLeaderDialog } from './maintenance-security-validation-contact-leader-dialog'
import {
  StyledLink,
  TypographyLink,
  useMaintenanceSecurityValidation,
} from './maintenance-security-validation.styles'

export type SecurityValidationPageProps = {
  api: SecurityValidationPageApi
}

export type DialogStateMaintenanceSecurityValidation = {
  isOpen: boolean
  confirmationButtonCallback?: () => void
}

export const MaintenanceSecurityValidation: React.FC<SecurityValidationPageProps> = ({ api }) => {
  const messages = usePageMessages(Page.MaintenanceSecurityValidation, {
    email: '',
    sms: '',
  }).messages as MaintenanceSecurityValidationMessages

  const { consultantId, countryId, companyId } = useContext(IdentityInfoContext)
  const sendCodeValidationStorage = (getObjectInStorage(
    SEND_CODE_VALIDATION_ORIGIN
  ) as SendCodeValidationStorage) || { origin: SendCodeValidationOrigin.CHANGE_ADDRESS }

  const [modalStatus, setModalStatus] = useState<DialogStateMaintenanceSecurityValidation>({ isOpen: false })
  const [isLoading, setIsLoading] = useState(true)
  const [openSubmitLoading, setOpenSubmitLoading] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [radioButton, SetRadioButton] = useState('')
  const [isInvalidSMS, setIsInvalidSMS] = useState(true)

  const [cnContacts, setCNContacts] = useState<MaintenanceSecurityValidationOptions>({
    email: {
      sequence: '',
      value: '',
    },
    sms: {
      sequence: '',
      value: '',
    },
  } as MaintenanceSecurityValidationOptions)

  const getContacts = useCallback(async () => {
    try {
      const response = await api.getCNContacts({ personId: consultantId, countryId, companyId })
      setCNContacts(response)

      const isInvalid = response.sms.value.includes('#')
      setIsInvalidSMS(isInvalid)
    } catch {
      setIsErrorDialogOpen(true)
      console.warn('Error on get consultant contacts')
    } finally {
      setIsLoading(false)
    }
  }, [api, companyId, countryId, consultantId])

  useEffect(() => {
    getContacts()
  }, [getContacts])

  const classes = useMaintenanceSecurityValidation()

  const radioButtonName = 'securityRadioButton'
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      [radioButtonName]: '',
    },
  })
  const { control } = formMethods

  const goBackPreviousPage = () => {
    if (sendCodeValidationStorage.origin === SendCodeValidationOrigin.CHANGE_ADDRESS) {
      const urlMaintenanceAddressPage = createUrlAddressMaintenance({
        url: `mfe-register/maintenanceAddressPage/${consultantId}`,
        country: countryId,
      })

      window.location.assign(urlMaintenanceAddressPage)
    } else {
      const urlMaintenanceRegisterPage = createUrlAddressMaintenance({
        url: `mfe-register/maintenanceRegisterPage/${consultantId}`,
        country: countryId,
      })
      window.location.assign(urlMaintenanceRegisterPage)
    }
  }

  const handleSubmit = async () => {
    setOpenSubmitLoading(true)
    try {
      const channel = radioButton
      const { sequence } = cnContacts[channel.toLowerCase()]

      await api.sendCodeValidation({
        countryId,
        companyId,
        personId: consultantId,
        channel,
        origin: sendCodeValidationStorage.origin,
      })
      setObjectInStorage(CHANNEL_STORAGE_NAME, { channel, sequence })

      if (sendCodeValidationStorage.origin === SendCodeValidationOrigin.CHANGE_ADDRESS) {
        const urlMaintenanceValidationCode = createUrlAddressMaintenance({
          url: `mfe-register/maintenanceValidationCode/${consultantId}`,
          country: countryId,
        })

        window.location.assign(urlMaintenanceValidationCode)
      } else {
        const urlMaintenanceValidationCodePhoneEmail = createUrlAddressMaintenance({
          url: `mfe-register/maintenanceValidationCodePhoneEmail/${consultantId}`,
          country: countryId,
        })

        window.location.assign(urlMaintenanceValidationCodePhoneEmail)
      }
    } catch (error) {
      console.error(error)
      setIsErrorDialogOpen(true)
      setOpenSubmitLoading(false)
    }
  }

  return (
    <Container
      nextButtonLabel={messages.labels.saveButton}
      onNextButtonClick={handleSubmit}
      disableNextButton={Boolean(!radioButton)}
      previousButtonLabel={messages.labels.previousButton}
      onPreviousButtonClick={goBackPreviousPage}
      isLoading={isLoading}
    >
      <Typography variant="h6">{messages.title}</Typography>

      <div className={classes.subtitle}>
        <Typography>
          {sendCodeValidationStorage.origin === SendCodeValidationOrigin.CHANGE_ADDRESS
            ? messages.subtitle
            : messages.contactSubtitle}
        </Typography>
      </div>

      <Typography>{messages.question}</Typography>

      <div className={classes.wrapperInputs}>
        {cnContacts && cnContacts.email && cnContacts.email.value && (
          <ControlledFVRadioButton
            id="security-validation-fv-radio-button-email-id"
            testID="security-validation-fv-radio-button-email-test-id"
            name={radioButtonName}
            label={`${messages.labels.email}${cnContacts.email.value.toLocaleLowerCase()}`}
            value="EMAIL"
            onChange={() => SetRadioButton('EMAIL')}
            control={control}
            checked={radioButton === 'EMAIL'}
            className={classes.emailRadioButton}
            required
          />
        )}

        {!isInvalidSMS && (
          <ControlledFVRadioButton
            id="security-validation-fv-radio-button-sms-id"
            testID="security-validation-fv-radio-button-sms-test-id"
            name={radioButtonName}
            label={`${messages.labels.sms}${cnContacts.sms.value}`}
            value="SMS"
            onChange={() => SetRadioButton('SMS')}
            control={control}
            checked={radioButton === 'SMS'}
            required
          />
        )}
      </div>
      <TypographyLink variant="body2" color="textSecondary">
        <StyledLink onClick={() => setModalStatus({ isOpen: true })}>{messages.noAcessContacts}</StyledLink>
      </TypographyLink>
      {modalStatus.isOpen && (
        <MaintenanceSecurityValidationContactLeaderDialog
          open={modalStatus.isOpen}
          onClickConfirmButton={() => setModalStatus({ isOpen: false })}
        />
      )}
      <DialogAlert
        show={isErrorDialogOpen}
        messages={{ infoText: messages.unexpectedError, closeButtonText: messages.closeButtonText }}
        closeDialogCallback={() => setIsErrorDialogOpen(false)}
      />
      <DialogWithLoader isOpen={openSubmitLoading} />
    </Container>
  )
}
