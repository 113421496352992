import { CompanyId } from '../company/company-id'
import { CountryId } from '../country'

export enum TenantId {
  NaturaCHL = '51326ed9-1db3-4af0-bf3e-40190a68e98f',
  NaturaPER = '64d0a430-e367-42f3-8e9b-6eb1ae60d7bc',
  NaturaMYS = 'fd11f2e7-b101-4e7e-9a92-0b0a76c05264',
  NaturaCOL = '8c776e6f-d2f7-4f58-b996-16120555ceb1',
  NaturaARG = '8374a616-8b1e-46af-8d41-2bcf683234db',
  NaturaBRA = 'f9fc6810-691b-4102-8296-7bd19687ed5b',
  NaturaMEX = '4cb704d1-0122-4b88-a7a6-9eef40741416',
  AvonMEX = '3c9c51b3-0110-4b95-b5de-ca31a01b585c',
  AvonBRA = '9af7616d-656c-4a59-8af3-0413615f6dd3',
}

export const getTenantKeyByValue = (tenantValue: TenantId) => Object.entries(TenantId).find(([, v]) => v === tenantValue)[0]

export const tenantIdMapper = {
  [CompanyId.Natura]: {
    [TenantId.NaturaCHL]: CountryId.Chile,
    [TenantId.NaturaPER]: CountryId.Peru,
    [TenantId.NaturaMYS]: CountryId.Malaysia,
    [TenantId.NaturaCOL]: CountryId.Colombia,
    [TenantId.NaturaBRA]: CountryId.Brazil,
    [TenantId.NaturaARG]: CountryId.Argentina,
    [TenantId.NaturaMEX]: CountryId.Mexico,
  },
  [CompanyId.Avon]: {
    [TenantId.AvonMEX]: CountryId.Mexico,
    [TenantId.AvonBRA]: CountryId.Brazil,
  },
}

export const countryCompanyToTenantIdMapper = {
  [CompanyId.Natura]: {
    [CountryId.Chile] : TenantId.NaturaCHL,
    [CountryId.Peru] : TenantId.NaturaPER,
    [CountryId.Malaysia] : TenantId.NaturaMYS,
    [CountryId.Colombia]: TenantId.NaturaCOL,
    [CountryId.Brazil]: TenantId.NaturaBRA,
    [CountryId.Argentina]: TenantId.NaturaARG,
    [CountryId.Mexico]: TenantId.NaturaMEX,
  },
  [CompanyId.Avon]: {
    [CountryId.Mexico]: TenantId.AvonMEX,
    [CountryId.Brazil]: TenantId.AvonBRA,
  },
}
