import { MaintenanceRegisterMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenanceRegister(translate: TFunction): MaintenanceRegisterMessages {
  return {
    title: translate('maintenancePage.title'),
    successMessage: translate('maintenancePage.successMessage'),
    unexpectedErrorMessage: translate('maintenancePage.unexpectedErrorMessage'),
    duplicatedEmailErrorMessage: translate('maintenancePage.duplicatedEmailErrorMessage'),
    emailAlreadyInUseErrorMessage: translate('maintenancePage.emailAlreadyInUseErrorMessage'),
    dialogButtonName: translate('maintenancePage.dialogButtonName'),
    cancelDialogButtonName: translate('maintenancePage.cancelDialogButtonName'),
    confirmationPhoneToRemoveMessage: translate('maintenancePage.confirmationPhoneToRemoveMessage'),
    nextButtonLabel: translate('maintenancePage.nextButtonLabel'),
    backButtonLabel: translate('maintenancePage.backButtonLabel'),
    requiredErrorMessage: translate('requiredErrorMessage'),
    firstDigitErrorMessage: translate('maintenancePage.firstDigitErrorMessage'),
    lengthErrorMessage: translate('maintenancePage.lengthErrorMessage'),
    email: {
      label: translate('maintenancePage.email.label'),
      placeholder: translate('maintenancePage.email.placeholder'),
      errorMessage: translate('maintenancePage.email.errorMessage'),
    },
    phoneNumber: {
      label: translate('maintenancePage.phoneNumber.label'),
      secondaryPhoneLabel: translate('maintenancePage.phoneNumber.secondaryPhoneLabel'),
      placeholder: translate('maintenancePage.phoneNumber.placeholder'),
      errorMessage: translate('maintenancePage.phoneNumber.errorMessage'),
      addPhoneNumber: translate('maintenancePage.phoneNumber.addPhoneNumber'),
      removePhoneNumber: translate('maintenancePage.phoneNumber.removePhoneNumber'),
    },
  }
}
