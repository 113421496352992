import {
  FVTextField,
  useControlledTextFieldStyles,
} from '@/main/components/text-field/controlled-text-field.styles'
import { getLabel } from '@/main/components/text-field/helper/get-label'
import { CustomChangeEvent, handleOnChange } from '@/main/components/text-field/helper/handle-onchange'
import { getEloToggleByCountry } from '@/utils/getEloToggle'
import { Typography } from '@naturacosmeticos/natds-web'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import React, { FocusEvent, useEffect, useState } from 'react'
import { Controller, ControllerProps, FieldErrors, useFormContext } from 'react-hook-form'
import { Checkbox, CheckboxProps } from '../checkbox'
import { ParseStringToReact } from '../string-to-react'
import { getFinalErrorMessage, StringOrObjectOfStrings } from './helper/error-message-getter'
import { validateIsFilled } from './helper/validate-is-filled'

export type ControlledTextFieldProps = {
  id: string
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  errorMessage?: StringOrObjectOfStrings
  validChars?: RegExp
  infoText?: string
  mask?: Mask
  errors?: FieldErrors
  showAsterisk?: boolean
  isELOTheme?: boolean
  optionalText?: string
  isUpperCase?: boolean
  hasBlankLabel?: boolean
  icon?: Object
  type?: string
  shouldValidateIsNotOnlySpaces?: boolean
  customOnChange?: (inputValue: string) => void
  customOnBlur?: (inputValue: string) => void
  checkbox?: Pick<CheckboxProps, 'color'>
} & Pick<ControllerProps<'input'>, 'rules' | 'control'> &
  (NoValueCheckboxPassed | NoValueCheckboxNoPassed)

type NoValueCheckboxPassed = {
  noValueCheckbox?: true
  noValueLabel: string
}

type NoValueCheckboxNoPassed = {
  noValueCheckbox?: boolean
  noValueLabel?: string
}

export const ControlledTextField: React.FC<ControlledTextFieldProps> = ({
  id,
  control,
  label,
  placeholder,
  required,
  disabled,
  rules,
  errorMessage,
  validChars,
  infoText,
  mask,
  errors,
  showAsterisk = true,
  optionalText,
  isUpperCase = false,
  hasBlankLabel,
  icon,
  shouldValidateIsNotOnlySpaces = false, // this property should be used only when the field is required and has no other validations!
  customOnChange,
  customOnBlur,
  noValueCheckbox,
  noValueLabel,
  checkbox = { color: getEloToggleByCountry() ? 'primary' : 'secondary' },
}) => {
  const { setValue, watch } = useFormContext()
  const value = watch(id)
  const [noValue, setNoValue] = useState(value === noValueLabel)

  if (required && shouldValidateIsNotOnlySpaces) {
    rules = { ...rules, validate: validateIsFilled }
    errorMessage = rules.required as string
  }
  const controlledTextFieldClasses = useControlledTextFieldStyles()

  const finalErrorMessage = getFinalErrorMessage(errors, errorMessage, id)
  const labelFormatted = getLabel({
    labelText: label,
    showAsterisk,
    required,
    optionalText,
    hasBlankLabel,
  })

  const handleNoValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = Boolean(event.target.checked)

    setNoValue(checked)

    const value = checked ? noValueLabel : ''

    setValue(id, value, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (noValueCheckbox) {
      setNoValue(value === noValueLabel)
    }
  }, [noValueCheckbox, noValueLabel, value])

  const disabledFornNoValueCheckbox = noValueCheckbox && noValue
  const disabledOrNoValue = disabled || disabledFornNoValueCheckbox

  return (
    <div className={controlledTextFieldClasses.container}>
      <Controller
        name={id}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ onBlur, onChange, value, name, ref }, { invalid }) => (
          <>
            <FVTextField
              isELOTheme={getEloToggleByCountry()}
              ref={ref}
              onBlur={(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                onBlur()
                if (customOnBlur) {
                  customOnBlur(event.target.value)
                }
              }}
              onChange={(event: CustomChangeEvent) => {
                handleOnChange({
                  event,
                  onChange,
                  validChars,
                  isUpperCase,
                })
                if (customOnChange) {
                  customOnChange(event.target.value)
                }
              }}
              value={value}
              name={name}
              id={id}
              data-testid={id}
              label={labelFormatted}
              placeholder={placeholder}
              disabled={disabledOrNoValue}
              state={invalid ? 'error' : undefined}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              helpText={invalid ? invalid.message || finalErrorMessage : undefined}
              mask={mask}
              icon={icon}
            />
            {infoText && (
              <Typography variant="caption" color="textSecondary">
                <ParseStringToReact stringToParse={infoText} />
              </Typography>
            )}

            {!disabled && noValueCheckbox && (
              <Checkbox
                color={checkbox.color}
                label={noValueLabel}
                onChange={(e) => handleNoValue(e)}
                checked={noValue}
              />
            )}
          </>
        )}
      />
    </div>
  )
}
