import { call, put } from 'redux-saga/effects'

import { CompleteRegistrationParams } from '@/domain/use-cases/complete-registration'
import { makeCompleteRegistration } from '@/main/factories/remote'
import { BadRequestError, ConflictError } from '@/data/protocols/errors'
import { completeRegistrationSuccess, completeRegistrationError } from '../actions'

export function* completeRegistration(action) {
  const {
    personId,
    countryId,
    businessModel,
    functionId,
    role,
    companyId,
    sourceSystem,
    authorization,
    userRole,
    currentCreatedFrom,
    registrantId,
    introducerSellerId,
  } = action.payload

  try {
    const completeRegistration = makeCompleteRegistration()
    const params: CompleteRegistrationParams = {
      countryId,
      personId,
      businessModel,
      functionId,
      role,
      companyId,
      sourceSystem,
      authorization,
      userRole,
      currentCreatedFrom,
      registrantId,
      introducerSellerId,
    }
    const completed = yield call(completeRegistration.complete, params)
    const error = !completed
    yield put(completeRegistrationSuccess(error))
  } catch (errorResponse) {
    switch (errorResponse.constructor) {
      case ConflictError:
      case BadRequestError:
        break
      default:
        break
    }
    yield put(completeRegistrationError())
  }
}
