import { Icon, Spacing } from '@naturacosmeticos/natds-web'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  SendCodeValidationOrigin,
  SendCodeValidationStorage,
} from '@/data/use-cases/register-maintenance-security-validation/remote-send-code-validation'
import { SEND_CODE_VALIDATION_ORIGIN } from '@/domain/models/address'
import { Country } from '@/domain/models/country/country-id'
import { MaintenanceRegisterMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { BUSINESS_MODELS, FUNCTION_IDS, PhoneType, ROLES } from '@/domain/models/person'
import { PHONE_EMAIL_STORAGE_NAME } from '@/domain/models/person/phone-email-storge'
import { MaintenanceRegisterConfigs } from '@/domain/models/tenant/tenant-configs'
import { redirectToProfile } from '@/domain/models/tenant/tenant-configs/helpers/redirect'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import {
  GetConsultantGvResponsibleParams,
  GetPersonDataParams,
  PersonData,
  PersonDataResponse,
  PersonGvResponsible,
} from '@/domain/use-cases/person'
import {
  Email,
  Phone,
  SaveConsultantInfoParams,
  ValidIfEmailAlreadyExistParams,
} from '@/domain/use-cases/register-maintenance/save-consultant-info'
import { Button } from '@/main/components'
import { EmailsType, IdentityInfoContext, PhonesType } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { setObjectInStorage } from '@/main/hooks/useStorage'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { getOnlyNumbers } from '@/main/pages/register-maintenance/commons/common-fuctions'
import { DialogWithLoader } from '@/main/pages/register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'
import { MAIN_EMAIL_TYPE_ID } from '@/utils/constants'
import { useHistory } from 'react-router-dom'
import { EmailMessages, EmailTextField } from '../personal-data/fields'
import { Container } from '../register-maintenance-address/components/pages-structures'
import { RegisterMaitenancePageApi } from './api/make-register-maintenance-page-api'
import { MaintenanceDialog } from './fields/maintenance-dialog'
import { PhoneNumber } from './fields/phone-number'
import { StyledTypography } from './maintenance-register-page.styles'

export type MaintenanceRegisterPageProps = {
  api: RegisterMaitenancePageApi
}

export type FormMaintenanceRegister = {
  email: string
  phoneNumber: string
  phoneNumberSecondary?: string
}

const MILISECONDS_IN_ONE_DAY = 1000 * 60 * 60 * 24

export type Toggles = {
  profileTurnOnMaintenanceTokenSecurityToggle?: boolean
  profileContactMaintenanceTokenSecurityGVArrayToggle?: Array<string>
  profileContactMaintenanceTokenSecurityGVArrayActiveToggle?: boolean
}

export type DialogState = {
  message?: string
  isOpen: boolean
  cancelButtonName?: string
  confirmationButtonCallback?: () => void
}

type SavedContactInfo = Pick<PersonData, 'emails' | 'phones' | 'birthday' | 'gender' | 'motherName' | 'name'>

const getFirst = (arr: Array<any>) => {
  const [first] = arr || undefined
  return first
}

const getSecond = (phones: PhonesType[]) => getFirst(phones.slice(1, 2))

export const getFormattedPhoneNumber = (phone: PhonesType): string => {
  if (!phone) return undefined

  const areaCode = phone?.areaCode || ''
  const phoneNumber = phone?.phoneNumber || ''
  return areaCode.concat(phoneNumber)
}

export const MaintenanceRegisterPage = ({ api }: MaintenanceRegisterPageProps) => {
  const [modalStatus, setModalStatus] = useState<DialogState>({ message: '', isOpen: false })
  const [isLoading, setIsLoading] = useState(true)
  const [openSubmitLoading, setOpenSubmitLoading] = useState(false)
  const [savedContactInfos, setSavedContactInfos] = useState<SavedContactInfo>()
  const [showRemoveButton, setShowRemoveButton] = useState<boolean>(false)
  const [GVCode, setGVCode] = useState<string>('')

  const {
    consultantId,
    personId,
    sourceSystem,
    countryId,
    companyId,
    isCN,
    businessModel,
    toggles = {},
  } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const history = useHistory()

  const {
    shouldEditEmail,
    shouldEditPhone,
    showPhoneNumberSecondary,
    phoneNumberOptions,
    phoneNumberAreaCode,
    phoneNumberSecondaryOptions,
  } = useTenantConfigs(tenantId, Page.MaintenanceRegister) as MaintenanceRegisterConfigs
  const messages = usePageMessages(Page.MaintenanceRegister).messages as MaintenanceRegisterMessages

  const phoneMessages = {
    label: messages.phoneNumber?.label,
    secondaryPhoneLabel: messages.phoneNumber.secondaryPhoneLabel,
    placeholder: messages.phoneNumber?.placeholder,
    requiredErrorMessage: messages.requiredErrorMessage,
    firstDigitErrorMessage: messages.firstDigitErrorMessage,
    lengthErrorMessage: messages.lengthErrorMessage,
    addPhoneNumber: messages.phoneNumber.addPhoneNumber,
    removePhoneNumber: messages.phoneNumber.removePhoneNumber,
  }
  const emailMessages: EmailMessages = {
    label: messages.email?.label,
    placeholder: messages.email?.placeholder,
    requiredErrorMessage: messages.requiredErrorMessage,
    errorMessage: messages.email.errorMessage,
  }

  const getErrorMessagesOnSave = (error: any) => {
    const isEmailAlreadyInUseError = error?.data?.code === 'PR-0002'
    return isEmailAlreadyInUseError ? messages.emailAlreadyInUseErrorMessage : messages.unexpectedErrorMessage
  }

  const formMethods = useForm<FormMaintenanceRegister>({
    mode: 'onTouched',
  })

  const { getValues, reset, formState } = formMethods

  useEffect(() => {
    const getConsultantInfo = async () => {
      const data: GetPersonDataParams = {
        personId: consultantId,
        companyId,
        countryId,
        relations: ['emails', 'telephones'],
      }
      try {
        const { person } = (await api.getConsultantInfo(data)) as PersonDataResponse
        const retrievedContactInfo = {
          email: getFirst(person.emails)?.email,
          phoneNumber: getFormattedPhoneNumber(getFirst(person.phones)),
          phoneNumberSecondary: getFormattedPhoneNumber(getSecond(person.phones)),
        }
        setSavedContactInfos({
          phones: person.phones,
          emails: person.emails,
          birthday: person.birthday,
          gender: person.gender,
          motherName: person.motherName,
          name: person.name,
        })

        setShowRemoveButton(retrievedContactInfo.phoneNumberSecondary !== undefined)
        reset(retrievedContactInfo)
      } catch (err) {
        console.warn('Error on get consultant')
      } finally {
        setIsLoading(false)
      }
    }

    getConsultantInfo()
  }, [api, companyId, consultantId, countryId, personId, reset])

  const getGVResponsibleCode = useCallback(async () => {
    const data: GetConsultantGvResponsibleParams = {
      personId: consultantId,
      companyId,
      country: Country[countryId],
      businessModelId: businessModel || 1,
    }

    try {
      const cache = JSON.parse(localStorage.getItem('gvCode'))

      if (!cache || cache.personId !== consultantId || cache.time < Date.now() - MILISECONDS_IN_ONE_DAY) {
        const { personCode } = (await api.getConsultantGvResponsible(data)) as PersonGvResponsible

        localStorage.setItem(
          'gvCode',
          JSON.stringify({ personId: consultantId, personCode, time: Date.now() })
        )

        setGVCode(personCode && personCode.toString())
      } else {
        setGVCode(cache.personCode)
      }
    } catch (err) {
      console.warn('Error on get consultant')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getGVResponsibleCode()
  }, [getGVResponsibleCode])

  const handleSubmit = async () => {
    const { phoneNumber, phoneNumberSecondary, email } = getValues()

    const validIfEmailAlreadyExistParams: ValidIfEmailAlreadyExistParams = {
      personId,
      companyId,
      countryId,
      requesterPersonId: personId,
      email,
    }

    let checkEmailAlreadyExist = false
    const oldEmail = getFirst(savedContactInfos.emails)?.email

    if (email !== oldEmail) {
      const resultcheckEmailAlreadyExist = await api.getValidIfEmailAlreadyExist(
        validIfEmailAlreadyExistParams
      )
      checkEmailAlreadyExist = resultcheckEmailAlreadyExist.emailAlreadyExists
    }

    if (isEmailAlreadyExists(email, savedContactInfos.emails) || checkEmailAlreadyExist) {
      setModalStatus({
        message: messages.duplicatedEmailErrorMessage,
        isOpen: true,
        confirmationButtonCallback: onCloseWithoutPreviousPage,
      })
      return
    }

    const data: SaveConsultantInfoParams = {
      personId: consultantId,
      companyId,
      countryId,
      birthday: savedContactInfos.birthday,
      gender: savedContactInfos.gender,
      motherName: savedContactInfos.motherName,
      name: savedContactInfos.name,
      requesterPersonId: personId,
      businessModel: BUSINESS_MODELS.DIRECT_SALE,
      role: ROLES.CONSULTANT,
      functionId: FUNCTION_IDS.BEAUTY_CONSULTANT,
    }

    if (
      isPhoneUpdated(getFirst(savedContactInfos.phones), phoneNumber) ||
      isPhoneUpdatedOrRemoved(getSecond(savedContactInfos.phones), phoneNumberSecondary)
    ) {
      data.phones = getUpdatedPhones(savedContactInfos, phoneNumber, phoneNumberSecondary)
    }

    if (isEmailUpdated(savedContactInfos, email) && savedContactInfos.emails.length > 0) {
      data.emails = getUpdatedEmails(savedContactInfos, email)
    } else {
      const getEmail = savedContactInfos?.emails?.find((e) => e.email)
      data.emails = [
        {
          sequence: getEmail?.sequence,
          email,
          type: MAIN_EMAIL_TYPE_ID
        },
      ]

    }

    const sendCodeValidationOrigin: SendCodeValidationStorage = {
      origin: SendCodeValidationOrigin.CHANGE_EMAIL_PHONE,
    }

    setObjectInStorage(PHONE_EMAIL_STORAGE_NAME, data)
    setObjectInStorage(SEND_CODE_VALIDATION_ORIGIN, sendCodeValidationOrigin)
    window.location.assign(`/webfv/mfe-register/maintenanceSecurityValidation/${consultantId}`)
  }

  const onClose = () => {
    setModalStatus({ isOpen: false })
    goBackPreviousPage()
  }

  const legacySubmit = async () => {
    try {
      const { phoneNumber, phoneNumberSecondary, email } = getValues()

      if (isEmailAlreadyExists(email, savedContactInfos.emails)) {
        setModalStatus({
          message: messages.duplicatedEmailErrorMessage,
          isOpen: true,
          confirmationButtonCallback: onCloseWithoutPreviousPage,
        })
        return
      }

      setOpenSubmitLoading(true)

      const data: SaveConsultantInfoParams = {
        personId,
        companyId,
        countryId,
        birthday: savedContactInfos.birthday,
        gender: savedContactInfos.gender,
        motherName: savedContactInfos.motherName,
        name: savedContactInfos.name,
        requesterPersonId: personId,
        businessModel: BUSINESS_MODELS.DIRECT_SALE,
        role: ROLES.CONSULTANT,
        functionId: isCN ? FUNCTION_IDS.BEAUTY_CONSULTANT : FUNCTION_IDS.LEADER,
      }

      if (
        isPhoneUpdated(getFirst(savedContactInfos.phones), phoneNumber) ||
        isPhoneUpdatedOrRemoved(getSecond(savedContactInfos.phones), phoneNumberSecondary)
      ) {
        data.phones = getUpdatedPhones(savedContactInfos, phoneNumber, phoneNumberSecondary)
      }

      if (isEmailUpdated(savedContactInfos, email) && savedContactInfos.emails.length > 0) {
        data.emails = getUpdatedEmails(savedContactInfos, email)
      } else {
        const getEmail = savedContactInfos?.emails?.find((e) => e.email)
        data.emails = [
          {
            sequence: getEmail?.sequence,
            email,
            type: MAIN_EMAIL_TYPE_ID
          },
        ]  
      }

      try {
        await api.saveConsultantInfoBasicData(data)

        setModalStatus({
          message: messages.successMessage,
          isOpen: true,
          confirmationButtonCallback: onClose,
        })

        setOpenSubmitLoading(false)
      } catch (error) {
        const message = getErrorMessagesOnSave(error)
        setModalStatus({ message, isOpen: true, confirmationButtonCallback: onCloseWithoutPreviousPage })
        setOpenSubmitLoading(false)
      }
    } catch (e) {
      setModalStatus({
        message: messages.unexpectedErrorMessage,
        isOpen: true,
        confirmationButtonCallback: onCloseWithoutPreviousPage,
      })
    } finally {
      setOpenSubmitLoading(false)
    }
  }

  const goBackPreviousPage = () => {
    if (isCN) {
      redirectToProfile(personId, sourceSystem)
    } else {
      window.location.reload()
      history.goBack()
    }
  }

  const onCloseWithoutPreviousPage = () => {
    setModalStatus({ isOpen: false })
  }

  const getUpdatedPhones = (
    savedContactInfos: SavedContactInfo,
    phoneNumber: string,
    secondaryPhoneNumber: string
  ): Phone[] => {
    const [first, second, ...otherPhones] = savedContactInfos.phones
    const originalPhoneNumber = first
    const originalSecondaryPhoneNumber = second

    const updatedPhone = mapToPhone(phoneNumber, originalPhoneNumber, PhoneType.WHATSAPP)
    const updatedSecondaryPhone = mapToPhone(
      secondaryPhoneNumber,
      originalSecondaryPhoneNumber,
      PhoneType.OTHERS
    )

    const newPhones = [updatedPhone]
    if (updatedSecondaryPhone) newPhones.push(updatedSecondaryPhone)

    const updatedOthersPhones: Phone[] = otherPhones.map((phone) => ({
      areaCode: phone.areaCode,
      phoneNumber: phone.phoneNumber,
      countryCode: phone.countryCode,
      type: phone.type,
      sequence: phone.sequence,
      updatedAt: phone.updatedAt,
    }))
    return [...newPhones, ...updatedOthersPhones]
  }

  const mapToPhone = (phoneNumber: string, originalPhone: PhonesType, phoneType: PhoneType): Phone => {
    if (!phoneNumber) return null

    const onlyNumber = getOnlyNumbers(phoneNumber)

    const updatedPhone: Phone = {
      areaCode: onlyNumber.substring(0, 2),
      phoneNumber: onlyNumber.substring(2, onlyNumber.length).trim(),
      countryCode: originalPhone?.countryCode || phoneNumberAreaCode,
      type: phoneType,
      sequence: originalPhone?.sequence,
    }

    return updatedPhone
  }

  const handlePhoneNumberButton = () => {
    if (showRemoveButton) {
      setModalStatus({
        message: messages.confirmationPhoneToRemoveMessage,
        isOpen: true,
        cancelButtonName: messages.cancelDialogButtonName,
        confirmationButtonCallback: () => {
          setShowRemoveButton(!showRemoveButton)
          onCloseWithoutPreviousPage()
        },
      })
    } else {
      setShowRemoveButton(!showRemoveButton)
    }
  }

  const isEmailAlreadyExists = (emailToCheck: string, emails: EmailsType[]) => {
    const [, ...emailsWithoutFirstElement] = emails
    return emailsWithoutFirstElement.some((emailType) => emailType.email === emailToCheck)
  }

  function verifyToggles(toggles: Toggles) {
    const {
      profileTurnOnMaintenanceTokenSecurityToggle,
      profileContactMaintenanceTokenSecurityGVArrayToggle = [],
      profileContactMaintenanceTokenSecurityGVArrayActiveToggle,
    } = toggles
    const arrayToVerify = profileContactMaintenanceTokenSecurityGVArrayToggle.map((item) => item.toString())
    const enableOTPServiceForLeaderGN = process.env.ENABLE_OTP_SERVICE_FOR_LEADER_GN_TOGGLE === 'true'

    if (!enableOTPServiceForLeaderGN && !isCN) {
      return false
    }

    if (
      profileTurnOnMaintenanceTokenSecurityToggle &&
      !profileContactMaintenanceTokenSecurityGVArrayActiveToggle
    ) {
      return true
    }

    if (
      profileTurnOnMaintenanceTokenSecurityToggle &&
      profileContactMaintenanceTokenSecurityGVArrayActiveToggle
    ) {
      if (GVCode && arrayToVerify.includes(GVCode.toString())) {
        return true
      }
    }

    return false
  }

  const finalSubmit = verifyToggles(toggles) ? handleSubmit : legacySubmit

  return (
    <Container
      nextButtonLabel={messages.nextButtonLabel}
      showSkipButton
      previousButtonLabel={messages.backButtonLabel}
      onPreviousButtonClick={goBackPreviousPage}
      onNextButtonClick={finalSubmit}
      disableNextButton={!formState.isValid}
      isLoading={isLoading}
      nextButtonTextInline
    >
      <StyledTypography variant="heading6">{messages.title}</StyledTypography>
      <FormProvider {...formMethods}>
        {shouldEditPhone ? (
          <>
            <PhoneNumber
              id="phoneNumber"
              name="phoneNumber"
              messages={phoneMessages}
              rules={{ required: true }}
              mask={phoneNumberOptions.mask}
              options={{
                maxLength: phoneNumberOptions.maxLength,
                minLength: phoneNumberOptions.minLength,
              }}
              mobileRegex={phoneNumberOptions?.mobileRegex}
            />

            {showPhoneNumberSecondary && (
              <>
                {showRemoveButton && (
                  <PhoneNumber
                    id="phoneNumberSecondary"
                    name="phoneNumberSecondary"
                    messages={{ ...phoneMessages, label: phoneMessages.secondaryPhoneLabel }}
                    rules={{ required: true }}
                    mask={phoneNumberSecondaryOptions?.mask}
                    options={{
                      maxLength: phoneNumberSecondaryOptions?.maxLength,
                      minLength: phoneNumberSecondaryOptions?.minLength,
                    }}
                    mobileRegex={phoneNumberSecondaryOptions?.mobileRegex}
                  />
                )}
                <Button
                  startIcon={
                    <Icon name={showRemoveButton ? 'filled-action-subtract' : 'filled-action-add'} />
                  }
                  onClick={handlePhoneNumberButton}
                >
                  {showRemoveButton ? phoneMessages.removePhoneNumber : phoneMessages.addPhoneNumber}
                </Button>
              </>
            )}
          </>
        ) : null}
        <Spacing className="natds2" display="flex" margin="tiny" />
        {shouldEditEmail ? (
          <EmailTextField
            messages={emailMessages}
            customProps={{
              shouldInfoTextBeVisible: true,
              icon: <Icon name="outlined-communication-email" size="small" />,
            }}
          />
        ) : null}
      </FormProvider>
      {modalStatus.isOpen && (
        <MaintenanceDialog
          message={modalStatus.message}
          open={modalStatus.isOpen}
          confirmButtonName={messages.dialogButtonName}
          onClickConfirmButton={modalStatus.confirmationButtonCallback}
          cancelButtonName={modalStatus.cancelButtonName}
          onClickCancelButton={onCloseWithoutPreviousPage}
        />
      )}
      <DialogWithLoader isOpen={openSubmitLoading} />
    </Container>
  )
}

function getUpdatedEmails(savedContactInfos: SavedContactInfo, email: string): Email[] {
  const [first, ...otherEmails] = savedContactInfos.emails
  const updatedEmail: Email = {
    email,
    type: first.type,
    sequence: first.sequence,
  }

  const updatedOtherEmails: Email[] = otherEmails.map((email) => ({
    email: email.email,
    type: email.type,
    sequence: email.sequence,
    updatedAt: email.updatedAt,
  }))
  return [updatedEmail, ...updatedOtherEmails]
}

function isPhoneUpdated(originalPhone: PhonesType, phoneNumber: string): boolean {
  return phoneNumber && originalPhone?.areaCode.concat(originalPhone?.phoneNumber) !== phoneNumber
}

function isPhoneUpdatedOrRemoved(originalPhone: PhonesType, phoneNumber: string): boolean {
  const removed = originalPhone && !phoneNumber
  return removed || isPhoneUpdated(originalPhone, phoneNumber)
}

function isEmailUpdated(savedContactInfos: SavedContactInfo, email: string): boolean {
  const savedEmail: EmailsType = getFirst(savedContactInfos.emails)
  return savedEmail?.email !== email
}
