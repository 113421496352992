const translationChile = require('../../../../public/locales/translation.NATURA_CHL.json')
const translationColombia = require('../../../../public/locales/translation.NATURA_COL.json')
const translationArgentina = require('../../../../public/locales/translation.NATURA_ARG.json')
const translationMalaysia = require('../../../../public/locales/translation.NATURA_MYS.json')
const translationAvonMexico = require('../../../../public/locales/translation.AVON_MEX.json')
const translationAvonBrazil = require('../../../../public/locales/translation.AVON_BRA.json')
const translationNaturaMexico = require('../../../../public/locales/translation.NATURA_MEX.json')
const translationBrasil = require('../../../../public/locales/translation.NATURA_BRA.json')
const translationPeru = require('../../../../public/locales/translation.NATURA_PER.json')

const defaultLanguage = 'CHL'
const languages = ['NATURA_PER', 'NATURA_CHL', 'NATURA_COL', 'NATURA_ARG', 'NATURA_MYS', 'AVON_MEX', 'NATURA_BRA', 'NATURA_MEX', 'AVON_BRA']

const namespaces = ['translation']
const namespacesSeparator = ':'

const resources = {
  NATURA_PER: {
    translation: translationPeru,
  },
  NATURA_CHL: {
    translation: translationChile,
  },
  NATURA_COL: {
    translation: translationColombia,
  },
  NATURA_ARG: {
    translation: translationArgentina,
  },
  NATURA_MYS: {
    translation: translationMalaysia,
  },
  AVON_MEX: {
    translation: translationAvonMexico,
  },
  NATURA_BRA: {
    translation: translationBrasil,
  },
  NATURA_MEX: {
    translation: translationNaturaMexico,
  },
  AVON_BRA: {
    translation: translationAvonBrazil,
  },
}

module.exports = {
  resources,
  defaultLanguage,
  languages,
  namespaces,
  namespacesSeparator,
}
