import { createPhoneNumberFirstDigitValidation } from '@/main/pages/personal-data/fields'
import { AddressPageForms } from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import {
  AddressConfigs,
  AttachmentsConfigs,
  AuthorizationCodeConfigs,
  CompletedConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'
import { AgreementProduct } from '../../agreement/agreement-product'
import { Document } from '../../documents/documents'
import { AttachmentType } from '../../attachments/attachments'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { VendorId } from '../../vendor/vendor-id'
import { AddressPageType, AddressOrder } from '../../address'
import { PhoneType } from '../../person'
import { companyIconName } from '../../company/company-icon-name'
import { CompanyName } from '../../company/company-name'

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.DniPeru,
      name: 'DNI',
      placeholder: 'DNI',
      validChars: /^[\d]{0,8}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 8,
        maxLength: 8,
      },
    },
    {
      type: Document.ForeignRegistry,
      name: 'Carnet de Extranjeria',
      placeholder: 'CE',
      validChars: /^[\d]{0,9}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 9,
        maxLength: 9,
      },
    },
    {
      type: Document.PassportPeru,
      name: 'Pasaporte',
      placeholder: 'pasaporte',
      validChars: /^[\d]{0,9}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 9,
        maxLength: 9,
      },
    },
  ],
  enableIndicationLink: true,
}

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: true,
  agreementProducts: [AgreementProduct.NATURA],
}

const authorizationCode: AuthorizationCodeConfigs = {
  phoneNumberHyphenIndex: undefined,
}

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.DEFAULT,
  vendorId: VendorId.MERCADO_PAGO,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const address: AddressConfigs = {
  pageType: AddressPageType.GeographicalLevels,
  shouldDisableTheFilledInput: false,
  geographicalLevels: { length: 3 },
  neighborhood: {
    rules: {
      maxLength: 30,
    },
    validChars: new RegExp(/^[a-zA-Z0-9 ãáéíóúÁÉÍÓÚñÑüÜ]+$/),
  },
  complement: {
    rules: {
      maxLength: 50, 
    },
    validChars: new RegExp(/^[a-zA-Z0-9 ãáéíóúÁÉÍÓÚñÑüÜ]+$/),
  },
  number: {
    validChars: new RegExp(/^[0-9]+$/),
    hasNoNumberCheckbox: true,
    rules: {
      maxLength: 6,
    },
  },
  reference: {
    maxLength: 100,
    validChars: new RegExp(/^[a-zA-Z0-9 ãáéíóúÁÉÍÓÚñÑüÜ]+$/),
  },
  street: {
    maxLength: 50
  },
  showDeliveryCheckbox: false,
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.COLOGNE,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ],
  showAlertOnApprovedPage: false,
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.DniPeru,
      name: 'DNI',
      attachments: [
        { title: 'Frente', type: AttachmentType.DniPeruFront },
        { title: 'Dorso', type: AttachmentType.DniPeruBack },
      ],
    },
    {
      type: Document.ForeignRegistry,
      name: 'Carnet de Extranjeria',
      attachments: [
        { title: 'Frente', type: AttachmentType.ForeignCarnetFront },
        { title: 'Dorso', type: AttachmentType.ForeignCarnetBack },
      ],
    },
    {
      type: Document.PassportPeru,
      name: 'Pasaporte',
      attachments: [
        { title: 'Frente', type: AttachmentType.PassportFront },
        { title: 'Dorso', type: AttachmentType.PassportBack },
      ],
    },
  ],
  canChooseDocument: false,
}

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: false,
  },
  shouldSearchIndication: true,
  shouldCheckStatus: true,
  shouldOnlyShowConfirmation: false,
  goToClickButton: {
    show: false,
  },
  companyIconName: companyIconName[CompanyName.Natura],
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = {
  shouldEditEmail: true,
  shouldEditPhone: true,
  phoneNumberAreaCode: '51',
  phoneNumberOptions: {
    mask: [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/],
    minLength: 9,
    maxLength: 9,
    mobileRegex: new RegExp(/^9/),
  },
  phoneNumberSecondaryOptions: {
    mask: [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/],
    minLength: 9,
    maxLength: 9,
    mobileRegex: new RegExp(/^9/),
  },
  showPhoneNumberSecondary: true,
}

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const personalData: PersonalDataConfigs = {
  shouldUsePhoneSelect: true,
  showAsteriskInRequiredLabel: false,
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/],
      firstDigitValidation: createPhoneNumberFirstDigitValidation('9'),
      length: 9,
    },
    {
      type: PhoneType.LINE,
      mask: [/[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 8,
    },
  ],
  shouldUseSeparatedNames: process.env.SHOULD_USE_SEPARATED_NAMES === 'true',
  namesOptions: ['firstName', 'lastName'],
  allowedCharsRegex: new RegExp(/^[a-zA-Z ]+$/),
  allocationStrategy: { levels: { length: 4 } },
}

const profile: ProfileConfigs = {
  showMyLeader: true,
  showCnCredit: true,
  contact: {
    phoneMask: {
      maskRegex: /^(\d{3})(\d{3})(\d{3}).*/,
      mask: '$1 $2 $3',
    },
    editUrl: '/mfe-register/maintenanceRegisterPage/',
    minegocioEditUrl: '/mfe-register/maintenanceRegisterPage/',
  },
  consultantData: {
    editUrl: '/mfe-register/maintenancePersonalDataPage/',
    minegocioEditUrl: '/mfe-register/maintenancePersonalDataPage',
  },
  digitalSpace: {
    digitalSpaceBaseUrl: 'https://www.natura.com.br/consultoria/',
    mySpaceDashboard:
      'https://cadastro-experiencia-digital-store-fe.qa.naturacloud.com/?country=pe&company=natura',
    openDigitalSpaceUrl:
      'https://cadastro-experiencia-digital-store-fe.qa.naturacloud.com/?country=pe&company=natura',
    showDigitalSpace: false,
  },
  address: {
    getAddress: ({
      street,
      number,
      complement,
      neighborhood,
      level1,
      level2,
      level3,
      level4,
      city,
      state,
      urbanizacion,
    }: AddressPageForms) => {
      const levelFour = typeof level4 === 'object' ? level4.label : level4
      const address = [
        street,
        number,
        urbanizacion,
        complement,
        neighborhood,
        level1.label,
        level2.label,
        level3.label,
        levelFour,
        city,
      ]
        .filter(Boolean)
        .join(', ')
      const showState = state ? ` - ${state.toUpperCase()}` : ''
      return `${address} ${showState}`
    },
    editUrl: '/mfe-register/maintenanceAddressPage/',
    minegocioEditUrl: '/mfe-register/maintenanceAddressPage',
  },
  hasEditProfile: false,
  hasSelfDeclaration: false,
  hasGender: false,
  hasSocialName: false,
  showPhoneNumberSecondary: true,
  showGroupSectorLeader: true,
  showGrowthPlan: true,
  mgmBanner: {
    show: true,
    showToConsultant: true,
    showToLeader: true,
    showToManager: false,
  },
}

export const naturaPerConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  address,
  digitalStore,
  digitalSignaturePage: null,
  completed,
  authorizationCode,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
