import { CountryId } from '@/domain/models/country'

export class Country {
  public readonly threeAlphaCountryCode

  public readonly twoAlphaCountryCode

  private readonly countryCodes = {
    MYS: CountryId.Malaysia,
    CLH: CountryId.Chile,
    PER: CountryId.Peru,
    BRA: CountryId.Brazil,
    MEX: CountryId.Mexico,
  }

  constructor(twoAlphaCountryCode: string) {
    twoAlphaCountryCode = twoAlphaCountryCode
      ? twoAlphaCountryCode.toUpperCase()
      : null
    this.twoAlphaCountryCode = this.countryCodes[twoAlphaCountryCode]
      ? twoAlphaCountryCode
      : null
    this.threeAlphaCountryCode = this.countryCodes[this.twoAlphaCountryCode] || null
  }
}
