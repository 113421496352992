import { ValidationStatus } from '@/main/components/upload-file/upload-file'

export type Attachment = {
  type: AttachmentType
  title: string
  message?: string
  status?: ValidationStatus
}

export enum AttachmentType {
  CnhFront = 1, // Carteira Nacional de Habilitação
  CnhBack = 2,
  RgFront = 3, // Registro Geral
  RgBack = 4,
  RneFront = 5, // Registro Nacional de Estrangeiros
  CrnmFront = 5,
  RneBack = 6,
  CrnmBack = 6,
  CieFront = 7,
  CitizenCardFront = 7,
  CitizenCardBack = 8,
  CieBack = 8,
  ForeignCardFront = 9,
  ForeignCardBack = 10,
  ComprobanteDomicilio = 11,
  TermsAndConditionAcceptance = 12, // Acceptacion de los términos y condiciones - Arquivo de áudio
  Selfie = 13, // Fotografia de la intencion - Selfie
  ProofOfEconomicActivity = 14,
  ComprovanteDomicilio = 15,
  DniPeruFront = 16,
  DniPeruBack = 17,
  ForeignCarnetFront = 18,
  ForeignCarnetBack = 19,
  PassportFront = 20,
  PassportBack = 21,
  RucFront = 22,
  RucBack = 23,
  RucPfFront = 24,
  RucPfBack = 25,
  ProfilePicture = 26,
  RutFront = 27,
  RutBack = 28,
  DniArgentinaFront = 29,
  DniArgentinaBack = 30,
  Curp = 31,
  IneFront = 33,
  IneBack = 34,
  PassportMexico = 36,
}
