import { CountryId } from '@/domain/models/country'

type CreateUrlAddressMaintenanceParams = {
  url: string
  country: CountryId
}

const PREFIX = {
  [CountryId.Argentina]: '/webfv',
  [CountryId.Brazil]: '/webfv',
  [CountryId.Chile]: '/webfv',
  [CountryId.Colombia]: '/webfv',
  [CountryId.Malaysia]: '/webfv',
  [CountryId.Mexico]: '/webfv',
  [CountryId.Peru]: '',
}

export const createUrlAddressMaintenance = ({ country, url }: CreateUrlAddressMaintenanceParams) => {
  return `${PREFIX[country]}/${url}`
}
